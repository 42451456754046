import React, { useContext } from 'react';
import { PlusCircleIcon, MinusCircleIcon, TrashIcon } from '../../components/icons'
import { CartContext } from '../../contexts/CartContext';

import { formatNumber, getDatebookImageFolder } from '../../helpers/utils';
import { Link } from 'react-router-dom';

const CartItem = ({ product }) => {

    const { increase, decrease, removeProduct } = useContext(CartContext);

    return (
        <div className="row no-gutters py-2">
            <div className="col-sm-2 p-2 d-none d-sm-block">
                <img
                    alt={product.displayName}
                    style={{ margin: "0 auto", maxHeight: "50px" }}
                    src={`/img/${getDatebookImageFolder(product.category)}/${product.thumbnailImage}`} className="img-fluid d-block" />
            </div>
            <div className="col-6 col-sm-4 p-2">
                <Link to={`/product/${product.id}`} style={{ textDecoration: 'none' }}>
                    <h5 className="mb-1">{product.displayName}</h5>
                    <p className="mb-1">Price: {formatNumber(product.price)} </p>
                </Link>
            </div>
            <div className="col-4 col-sm-2 p-2 text-center ">
                <p className="mb-0">Qty: {product.quantity}</p>
                <button
                    onClick={() => removeProduct(product)}
                    className="btn btn-sm btn-secondary mt-1"
                >
                    Remove item
                </button>
            </div>
            <div className="col-2 col-sm-4 p-2 text-right">
                <button
                    onClick={() => increase(product, 1)}
                    className="btn btn-primary btn-sm mr-2 mb-1">
                    <PlusCircleIcon width={"20px"} />
                </button>

                {
                    product.quantity > 1 &&
                    <button
                        onClick={() => decrease(product)}
                        className="btn btn-danger btn-sm mb-1">
                        <MinusCircleIcon width={"20px"} />
                    </button>
                }

                {
                    product.quantity === 1 &&
                    <button
                        onClick={() => removeProduct(product)}
                        className="btn btn-danger btn-sm mb-1">
                        <TrashIcon width={"20px"} />
                    </button>
                }

            </div>
            <span className="lineitem" style={{ display: "none" }} data-itemid={product.id} data-quantity={product.quantity} data-price={product.price} data-name={product.displayName}></span>
        </div>
    );
}

export default CartItem;