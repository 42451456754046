import React, { useState } from 'react';

// Append to Google Sheet using 
// Reference: https://community.bearer.sh/pushtogsheet/
const spreadsheetId = '11rYP2iJRb3Gcso89ncOZCgeo62UvDtbLlJ85EsIUywE'

const baseUrl = "https://pushtogsheet.herokuapp.com";
const query = `valueInputOption=RAW&pizzly_pkey=pope8Qy8qfYyppnHRMgLMpQ8MuEUKDGeyhfGCj`;
const url = new URL(`/proxy/google-sheets/${spreadsheetId}/values/A1:append?${query}`, baseUrl);

const appendToSheet = async ({ firstName, lastName, email }) => {
    // const data = [["firstname", "lastname"], ["John", "Doe"]]
    // data must be string[][]

    // TODO: (11/13) Use success/error toasts instead of alerts
    await fetch(url.href, {
        method: "POST",
        body: JSON.stringify({ values: [[firstName, lastName, email]] }),
        headers: { 'Pizzly-Auth-Id': '14bb1f80-2632-11eb-9cf6-1709ec832156' }
    })
        .then((res) => res.text())
        .then((res) => {
            alert("Successfully Signed Up!");
            // console.log(res)
        })
        .catch((err) => {
            alert("Something went wrong for sign up on our end!");
            console.error(err)
        })
}

const isValidEmail = (email) => {
    const regex = RegExp(/^[a-zA-Z0-9.!#$%&' * +/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
    return regex.test(email)
}

const SignUp = () => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")

    const [loading, setLoading] = useState(false)

    const onSubmit = async () => {
        // validation
        if (!isValidEmail(email)) {
            alert("Invalid email address")
            return
        }

        setLoading(true)
        await appendToSheet({ firstName, lastName, email })
        setLoading(false)

        // clear
        setFirstName("")
        setLastName("")
        setEmail("")
    }

    return (
        <div id="signup-form">
            <h1 className="my-5 text-center">Sign Up for Future Discounts</h1>
            <form>
                <div className="row mb-2">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="First name"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value) }}
                        />
                    </div>
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Last name"
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value) }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                    />
                    <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                </div>
            </form>
            <div className="text-center">
                <button
                    className="btn btn-primary btn-block"
                    onClick={onSubmit}
                    disabled={loading}
                >
                    Submit
                </button>
            </div>
        </div>

    )
}

export default SignUp