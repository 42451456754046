import React from 'react';
import { Link } from 'react-router-dom';
import { formatNumber, getDatebookImageFolder } from '../../helpers/utils';

const ProductItem = ({product}) => {

    return ( 
        <div className="card card-body">
            <Link to={`/product/${product.id}`} style={{ textDecoration: 'none' }}>
                <img style={{display: "block", margin: "0 auto 10px", maxHeight: "200px"}} className="img-fluid" 
                src={`/img/${getDatebookImageFolder(product.category)}/${product.thumbnailImage}`} alt=""/>
                <h3 className="my-2 text-center">{product.displayName}</h3>
                <h4 className="text-center">{formatNumber(product.price)}</h4>
            </Link>
            <div className="text-center">
                <Link to={`/product/${product.id}`} className="btn btn-link btn-sm mr-2">Details</Link>
            </div>
        </div>
     );
}
 
export default ProductItem;