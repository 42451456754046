import React from "react";

export const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
};

export const getDatebookImageFolder = (datebookType) => {
  switch (datebookType) {
    case "CITY":
      return "City Datebooks";
    case "PHOTO":
      return "Photo Datebooks";
    case "DAY_BY_DAY":
      return "Day By Day Datebooks";
    default:
      console.error("non-existent datebook type");
      return;
  }
};

export const getPDPText = (datebookType, displayName, includeEmailLink) => {
  let headerDescription, specificFeatureList;

  switch (datebookType) {
    case "CITY":
      headerDescription = (
        <p>
          The {displayName} Datebook Weekly Planner is not only a practical
          daily planner, but it is also a valuable resource guide! It will help
          you keep track of all important dates in your life and give you
          insight into the beautiful city you are in. Use it to keep track of
          important dates with weekly and monthly calendar layouts for you to
          write in your daily appointments and notes. Additionally, each
          Datebook includes:
        </p>
      );
      specificFeatureList = (
        <ul className="text-left">
          <li>
            Helpful city resources such as city maps, transportation
            information, sports information and schedules, local attractions, a
            calendar of city events, and more!
          </li>
          <li>3 Year Reference Calendar.</li>
          <li>List of important Dates and Holidays.</li>
          <li>Pages for Birthdays and Anniversaries.</li>
          <li>
            Restaurant & Entertainment and Book & Music & Movie Recommendations.
          </li>
        </ul>
      );
      return (
        <>
          {headerDescription}
          {specificFeatureList}
        </>
      );
    case "PHOTO":
      headerDescription = (
        <p>
          The {displayName} Photo City Datebook Weekly Planner includes scenic
          color photos of {displayName} on the cover and throughout the book. It
          is not only beautiful and practical- it includes valuable state
          resources as well! Use it to keep track of important dates with weekly
          and monthly calendar layouts that give you ample space to write out
          daily appointments and notes, or use as a helpful guide throughout{" "}
          {displayName}. Additionally, each Photo City Datebook includes:
        </p>
      );
      specificFeatureList = (
        <ul className="text-left">
          <li>
            Helpful city resources such as city maps, transportation
            information, sports information and schedules, local attractions, a
            calendar of city events, and more!
          </li>
          <li>3 Year Reference Calendar.</li>
          <li>List of important Dates and Holidays.</li>
          <li>Pages for Birthdays and Anniversaries.</li>
          <li>
            Restaurant & Entertainment and Book & Music & Movie Recommendations.
          </li>
        </ul>
      );
      return (
        <>
          {headerDescription}
          {specificFeatureList}
        </>
      );
    case "DAY_BY_DAY":
      headerDescription = (
        <p>
          Day by Day (Christian) Datebooks will help you keep track of all
          important dates in your life. Not only do they come with “Week at a
          Glance” and “Month at a Glance” pages for you to use as a planner, but
          they also include sixteen pages of biblical references in addition to:
        </p>
      );
      specificFeatureList = (
        <ul className="text-left">
          <li>List of books of the Bible</li>
          <li>List of Parables</li>
          <li>List of Ten Commandments</li>
          <li>List of Miracles</li>
          <li>List of Letters of Paul</li>
          <li>Glossary of theological terms</li>
          <li>Weight/Measurement conversions</li>
        </ul>
      );
      return (
        <>
          {headerDescription}
          {specificFeatureList}
        </>
      );
    default:
      return;
  }
};

function capitalizeWords(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function getPDPTitleMetadata(datebookType, displayName) {
  let retName = displayName;
  if (datebookType !== "DAY_BY_DAY") {
    retName = `${displayName} ${capitalizeWords(datebookType)}`;
  }

  return retName;
}

export function extractNumberFromPriceString(priceString) {
  const regex = /\$\d+(\.\d{2})?/; // Regular expression to match the price pattern ($X.XX)
  const matches = priceString?.match(regex);

  if (matches && matches.length > 0) {
    const price = matches[0].replace("$", "");
    return parseFloat(price);
  }

  return null; // Return null if no valid price is found
}
