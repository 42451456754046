import React, { useContext } from 'react';
import { CartContext } from '../../contexts/CartContext';
import { CartIcon } from '../icons';

import { Nav, Navbar, NavDropdown } from 'react-bootstrap'

const Header = () => {

    const { itemCount } = useContext(CartContext);

    return (
        <Navbar bg="danger" variant="dark" expand="lg" sticky="top">
            <Navbar.Brand href="/">Datebook Publishing</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto" style={{ flex: 1 }}>
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/about">About</Nav.Link>
                    <Nav.Link href="/layout-and-design">Layout and Design</Nav.Link>

                    <NavDropdown title="Explore Datebooks" id="basic-nav-dropdown" >
                        <NavDropdown.Item href="/city">City Datebooks</NavDropdown.Item>
                        <NavDropdown.Item href="/business-and-organization">Business and Organization</NavDropdown.Item>
                        <NavDropdown.Item href="/product/daybyday">Day By Day Datebooks</NavDropdown.Item>
                        <NavDropdown.Item href="/photo">Photo Datebooks</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/cart" className="ml-lg-auto"><CartIcon width="15" /> Cart ({itemCount})</Nav.Link>

                </Nav>
                {/* <Form inline>
                    <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                    <Button variant="outline-success">Search</Button>
                </Form> */}
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Header;