/**
 * This is the PDP
 * 
 * should have:
 *  - name
 *  - category
 *  - description 
 *  - image
 *  - price
 *  */

import React, { useContext, useState } from 'react';
import Layout from './Layout';

import { ProductsContext } from '../contexts/ProductsContext';
import { CartContext } from '../contexts/CartContext';

import { getPDPText, getPDPTitleMetadata } from '../helpers/utils'

import {
    useParams,
    useHistory
} from "react-router-dom";
import { Button, Alert } from 'react-bootstrap';
import { PlusCircleIcon, MinusCircleIcon } from './icons'

import ImageCarousel from './ImageCarousel'

const ProductPDP = () => {
    const { addProduct, cartItems, increase } = useContext(CartContext);

    const isInCart = product => {
        return !!cartItems.find(item => item.id === product.id);
    }

    const [quantity, setQuantity] = useState(1)
    const [submitStatus, setSubmitStatus] = useState({ status: null, message: "" })

    const { products } = useContext(ProductsContext)

    const { productId } = useParams();
    let history = useHistory();

    let product = products.filter((product) => product.id === productId)

    console.log(product)

    if (product.length < 1) {
        console.log('product does not exist with this product id')
        return (
            <h1>{`Product with id ${productId} does not exist`}</h1>
        );
    }

    product = product[0]; // get the actual product from the filtered list

    const onQuantityChange = (evt) => {
        let newQuantity = evt.target.value;
        setQuantity(newQuantity)
    }

    const onSubmit = () => {
        if (isNaN(quantity) || quantity < 1) {
            console.error('not a valid quantity')

            // set submit message state
            setSubmitStatus({ status: 'ERROR', message: 'Quantity must be at least 1.' })
            return;
        }

        if (!isInCart(product)) {
            addProduct(product, parseInt(quantity));
        }
        else {
            increase(product, parseInt(quantity));
        }
        console.log('submit clicked', product, quantity)

        // set submit message state
        setSubmitStatus({ status: 'SUCCESS', message: `Success! Added ${quantity} ${product.displayName} ${parseInt(quantity) > 1 ? 'datebooks' : 'datebook'} to cart` })
        setQuantity(1)
    }

    const renderSubmitStatus = ({ status, message }) => {
        if (!status) {
            return null;
        }

        switch (status) {
            case 'ERROR':
                return (
                    <Alert variant="danger"
                        onClose={() => { setSubmitStatus({ status: null, message: "" }) }}
                        dismissible
                    >
                        {message}
                    </Alert>);
            case 'SUCCESS':
                return (
                    <Alert variant="success"
                        onClose={() => { setSubmitStatus({ status: null, message: "" }) }}
                        dismissible
                    >
                        <p>{message}</p>
                        <hr />
                        <Alert.Link href="/cart">Go to cart.</Alert.Link>
                        <hr />
                        <Alert.Link onClick={() => {
                            setSubmitStatus({ status: null, message: "" })
                            history.goBack()
                        }}
                        >
                            Continue Shopping.
                        </Alert.Link>
                    </Alert>);
            default:
                return null;
        }
    }

    return (
        <Layout title={`${getPDPTitleMetadata(product.category, product.displayName)} Datebook`} description="Datebooks include helpful city resources including sixteen pages of city maps, transportation information, sports information and schedules, city attractions, important city phone numbers and calendar of events." >
            <div className="mt-5">
                <div className="row">
                    <div id="pdp-image" className="col-md-6 col-sm-12">
                        <ImageCarousel product={product}/>
                    </div>
                    <div id="pdp-info" className="col-md-6 col-sm-12">
                        <h1 id="display-name" className="mb-3">{product.displayName}</h1>

                        <div id="add-to-cart" className="py-3">
                            <h4 id="price">Price: {`$${product.price}`}</h4>

                            <div className="form-row mb-3 mt-5">
                                <label className="col-form-label">Quantity</label>
                                <button
                                    onClick={() => setQuantity(parseInt(quantity) - 1)}
                                    className="btn btn-primary btn-sm">
                                    <MinusCircleIcon width={"20px"} />
                                </button>

                                <div className="col-4">
                                    <input
                                        className="form-control text-center"
                                        type="number"
                                        value={quantity}
                                        id="example-number-input"
                                        onChange={onQuantityChange} />
                                </div>
                                <button
                                    onClick={() => setQuantity(parseInt(quantity) + 1)}
                                    className="btn btn-primary btn-sm">
                                    <PlusCircleIcon width={"20px"} />
                                </button>
                            </div>

                            <Button variant="danger" block onClick={onSubmit} className="mb-4">Add to cart</Button>

                            {renderSubmitStatus(submitStatus)}
                        </div>

                        <div id="description" className="my-4">
                            <h5>Product and Detail</h5>

                            {getPDPText(product.category, product.displayName)}

                            <p>The black leatherette covered book will fit easily into your purse or briefcase, with dimensions of 5 ½ x 7 ¾. Physical features include:</p>
                            <ul className="text-left">
                                <li>Black leatherette cover with foil embossed logo.</li>
                                <li>Wire-O binding to allow for full opening.</li>
                                <li>East tear off tabs to keep your place each week.</li>
                                <li>Pockets for loose papers such as receipts, event tickets, coupons, etc.</li>
                            </ul>
                            {/* <p><a href="mailto:kurt@datebook.com">Email us</a> if we can personalize a Datebook for you! (250 quantity minimum)</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ProductPDP;