export const cityDatebooks = [
  {
    id: "city_albuquerque",
    displayName: "Albuquerque",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Albuquerque Cover.jpg",
    images: [
      "Albuquerque Attractions.jpg",
      "Albuquerque Cover.jpg",
      "Albuquerque Map.jpg",
      "Albuquerque Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_atlanta",
    displayName: "Atlanta",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Atlanta Cover.jpg",
    images: [
      "Atlanta Attractions.jpg",
      "Atlanta Cover.jpg",
      "Atlanta Radio etc.jpg",
      "Atlanta Transportation.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_austin",
    displayName: "Austin",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Austin Cover.jpg",
    images: [
      "Austin Attractions.jpg",
      "Austin Cover.jpg",
      "Austin Map.jpg",
      "Austin Radio,, Airport.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_baltimore",
    displayName: "Baltimore",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Baltimore Cover.jpg",
    images: [
      "Baltimore Attractions.jpg",
      "Baltimore Cover.jpg",
      "Baltimore Map.jpg",
      "Baltimore Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_birmingham",
    displayName: "Birmingham",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Birmingham Cover.jpg",
    images: [
      "Birmingham Attractions.jpg",
      "Birmingham Cover.jpg",
      "Birmingham Map.jpg",
      "Birmingham Radio, Airport.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_boise",
    displayName: "Boise",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Boise Cover.jpg",
    images: [
      "Boise Cover.jpg",
      "Boise Map.jpg",
      "Boise Radio etc.jpg",
      "Boise Sports.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_boston",
    displayName: "Boston",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Boston Cover.jpg",
    images: [
      "Boston Attractions.jpg",
      "Boston Cover.jpg",
      "Boston Radio, Airport etc.jpg",
      "Boston Transit Map.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_buffalo",
    displayName: "Buffalo",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Buffalo Cover.jpg",
    images: [
      "Buffalo Attractions.jpg",
      "Buffalo Cover.jpg",
      "Buffalo Important Numbers.jpg",
      "Buffalo Transportation.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_charleston",
    displayName: "Charleston",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Charleston Cover.jpg",
    images: ["Charleston Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_charlotte",
    displayName: "Charlotte",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Charlotte Cover.jpg",
    images: ["Charlotte Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_chicago",
    displayName: "Chicago",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Chicago Cover.jpg",
    images: ["Chicago Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_cincinnati",
    displayName: "Cincinnati",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Cincinnati Cover.jpg",
    images: ["Cincinnati Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_cleveland",
    displayName: "Cleveland",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Cleveland Cover.jpg",
    images: ["Cleveland Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_colorado-springs",
    displayName: "Colorado Springs",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Colorado Springs Cover.jpg",
    images: ["Colorado Springs Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_columbus",
    displayName: "Columbus",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Columbus Cover.jpg",
    images: ["Columbus Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_corpus-christi",
    displayName: "Corpus Christi",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Corpus Christi Cover.jpg",
    images: ["Corpus Christi Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_dallas",
    displayName: "Dallas",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Dallas Cover.jpg",
    images: [
      "Dallas Attractions.jpg",
      "Dallas Cover.jpg",
      "Dallas Transit Map.jpg",
      "Dallas:Fort Worth Radio:Transport:Airport.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_denver",
    displayName: "Denver",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Denver Cover.jpg",
    images: ["Denver Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_des-moines",
    displayName: "Des Moines",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Des Moines Cover.jpg",
    images: ["Des Moines Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_detroit",
    displayName: "Detroit",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Detroit Cover.jpg",
    images: ["Detroit Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_el-paso",
    displayName: "El Paso",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "El Paso Cover.jpg",
    images: ["El Paso Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_fort-worth",
    displayName: "Fort Worth",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Fort Worth Cover.jpg",
    images: [
      "Dallas:Fort Worth Radio:Transport:Airport.jpg",
      "Fort Worth Attractions.jpg",
      "Fort Worth Cover.jpg",
      "Fort Worth Stockyard Map.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_grand-rapids",
    displayName: "Grand Rapids",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Grand Rapids Cover.jpg",
    images: [
      "Grand Rapids Attractions.jpg",
      "Grand Rapids Cover.jpg",
      "Grand Rapids Map.jpg",
      "Grand Rapids Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_houston",
    displayName: "Houston",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Houston Cover.jpg",
    images: [
      "Houston Attractions.jpg",
      "Houston Cover.jpg",
      "Houston Map.jpg",
      "Houston Radio, etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_indianapolis",
    displayName: "Indianapolis",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Indianapolis Cover.jpg",
    images: [
      "Indianapolis Attractions.jpg",
      "Indianapolis Cover.jpg",
      "Indianapolis Map.jpg",
      "Indianapolis Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_jackson",
    displayName: "Jackson",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Jackson Cover.jpg",
    images: [
      "Jackson Attractions.jpg",
      "Jackson Cover.jpg",
      "Jackson Map.jpg",
      "Jackson Radio etc.jpg",
      "Jackson Walking Tour.jpg",
      "Jacksonville Attractions.jpg",
      "Jacksonville Cover.jpg",
      "Jacksonville Radion etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_jacksonville",
    displayName: "Jacksonville",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Jacksonville Cover.jpg",
    images: [
      "Jacksonville Attractions.jpg",
      "Jacksonville Cover.jpg",
      "Jacksonville Radion etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_kansas-city",
    displayName: "Kansas City",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Kansas City Cover.jpg",
    images: [
      "Kansas City Attractions.jpg",
      "Kansas City Cover.jpg",
      "Kansas City Map.jpg",
      "Kansas City Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_las-vegas",
    displayName: "Las Vegas",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Las Vegas Cover.jpg",
    images: [
      "Las Vegas Attractions.jpg",
      "Las Vegas Cover.jpg",
      "Las Vegas Map.jpg",
      "Las Vegas Radio, Transportation, Airport.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_little-rock",
    displayName: "Little Rock",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Little Rock Cover.jpg",
    images: [
      "Little Rock Attractions.jpg",
      "Little Rock Cover.jpg",
      "Little Rock Map.jpg",
      "Little Rock Rasio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_los-angeles",
    displayName: "Los Angeles",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Los Angeles Cover.jpg",
    images: [
      "Los Angeles Attractions.jpg",
      "Los Angeles Cover.jpg",
      "Los Angeles Map.jpg",
      "Los Angeles Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_louisville",
    displayName: "Louisville",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Louisville Cover.jpg",
    images: [
      "Louisville Attractions.jpg",
      "Louisville Cover.jpg",
      "Louisville Map.jpg",
      "Louisville Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_memphis",
    displayName: "Memphis",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Memphis Cover.jpg",
    images: [
      "Memphis Attractions.jpg",
      "Memphis Cover.jpg",
      "Memphis Map.jpg",
      "Memphis Radionetc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_miami",
    displayName: "Miami",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Miami Cover.jpg",
    images: [
      "Miami Attractions.jpg",
      "Miami Cover.jpg",
      "Miami Map.jpg",
      "Miami Radio, Airport.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_milwaukee",
    displayName: "Milwaukee",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Milwaukee Cover.jpg",
    images: [
      "Milwaukee Attractions.jpg",
      "Milwaukee Cover.jpg",
      "Milwaukee Map.jpg",
      "Milwaukee Radio, Airpor.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_nashville",
    displayName: "Nashville",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Nashville Cover.jpg",
    images: [
      "Nashville Attractions.jpg",
      "Nashville Cover.jpg",
      "Nashville Map.jpg",
      "Nashville Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_new-orleans",
    displayName: "New Orleans",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "New Orleans Cover.jpg",
    images: [
      "New Orleans Cover.jpg",
      "New Orleans Map.jpg",
      "New Orleans Radio etc.jpg",
      "New Orleanse Attractions.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_new-york",
    displayName: "New York",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "New York Cover.jpg",
    images: [
      "New York Attractions.jpg",
      "New York City Radio, Airport.jpg",
      "New York Cover.jpg",
      "New York Vacation Regions.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_oahu",
    displayName: "Oahu",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Oahu Cover.jpg",
    images: ["Oahu Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_oklahoma-city",
    displayName: "Oklahoma City",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Oklahoma City Cover.jpg",
    images: [
      "Oklahoma City Attractions.jpg",
      "Oklahoma City Cover.jpg",
      "Oklahoma City Map.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_omaha",
    displayName: "Omaha",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Omaha Cover.jpg",
    images: [
      "Omaha Arts and Entertainment.jpg",
      "Omaha Cover.jpg",
      "Omaha Radio etc.jpg",
      "Omaha Recreation .jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_orlando",
    displayName: "Orlando",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Orlando Cover.jpg",
    images: [
      "Orlando Cover.jpg",
      "Orlando Map.jpg",
      "Orlando Radio etc.jpg",
      "Orlando Sports.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_philadelphia",
    displayName: "Philadelphia",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Philadelphia Cover.jpg",
    images: [
      "Philadelphia Cover.jpg",
      "Philadelphia Map.jpg",
      "Philadelphia Radio etc.jpg",
      "Philadelphia Sports and Attractions.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_phoenix",
    displayName: "Phoenix",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Phoenix Cover.jpg",
    images: [
      "Phoenix Attractions.jpg",
      "Phoenix Cover.jpg",
      "Phoenix Important Numbers.jpg",
      "Phoenix Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_pittsburgh",
    displayName: "Pittsburgh",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Pittsburgh Cover.jpg",
    images: [
      "Pittsburgh Attractions.jpg",
      "Pittsburgh Cover.jpg",
      "Pittsburgh Map.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_portland",
    displayName: "Portland",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Portland Cover.jpg",
    images: [
      "Portland Attractions.jpg",
      "Portland Cover.jpg",
      "Portland Map.jpg",
      "Portland Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_raleigh",
    displayName: "Raleigh",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Raleigh Cover.jpg",
    images: [
      "Raleigh Attractions.jpg",
      "Raleigh Cover.jpg",
      "Raleigh Map.jpg",
      "Raleigh Radio.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_sacramento",
    displayName: "Sacramento",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Sacramento Cover.jpg",
    images: [
      "Sacramento Cover.jpg",
      "Sacramento Important Numbers.jpg",
      "Sacramento Radio etc.jpg",
      "Sacramento Transit.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_salt-lake-city",
    displayName: "Salt Lake City",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Salt Lake City Cover.jpg",
    images: [
      "Salt Lake City Cover.jpg",
      "Salt Lake City Map.jpg",
      "Salt Lake City Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_san-antonio",
    displayName: "San Antonio",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "San Antonio Cover.jpg",
    images: [
      "San Antonio Attractions.jpg",
      "San Antonio Cover.jpg",
      "San Antonio Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_san-diego",
    displayName: "San Diego",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "San Diego Cover.jpg",
    images: [
      "San Diego Cover.jpg",
      "San Diego Map.jpg",
      "San Diego Radio etc.jpg",
      "San Diego Sports.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_san-francisco",
    displayName: "San Francisco",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "San Francisco Cover.jpg",
    images: [
      "San Francisco Attractions.jpg",
      "San Francisco Cover.jpg",
      "San Francisco Map.jpg",
      "San Francisco Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_san-jose",
    displayName: "San Jose",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "San Jose Cover.jpg",
    images: [
      "San Jose Attractions.jpg",
      "San Jose Cover.jpg",
      "San Jose Map.jpg",
      "San Jose Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_seattle",
    displayName: "Seattle",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Seattle Cover.jpg",
    images: [
      "Seattle Attractions.jpg",
      "Seattle Cover.jpg",
      "Seattle Map.jpg",
      "Seattle Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_st-louis",
    displayName: "St Louis",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "St Louis Cover.jpg",
    images: [
      "St Louis Attractions.jpg",
      "St Louis Cover.jpg",
      "St Louis Map.jpg",
      "St Louis Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_tampa-bay",
    displayName: "Tampa Bay",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Tampa Bay Cover.jpg",
    images: [
      "Tampa Bay Cover.jpg",
      "Tampa Bay Map.jpg",
      "Tampa Bay Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_tulsa",
    displayName: "Tulsa",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Tulsa Cover.jpg",
    images: [
      "Tulsa Attractions.jpg",
      "Tulsa Cover.jpg",
      "Tulsa Map.jpg",
      "Tulsa Radio etc.jpg",
    ],
    category: "CITY",
  },
  {
    id: "city_tuscon",
    displayName: "Tuscon",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Tuscon Cover.jpg",
    images: ["Tuscon Cover.jpg", "Tuscon Map.jpg"],
    category: "CITY",
  },
  {
    id: "city_twin-cities",
    displayName: "Twin Cities",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Twin Cities Cover.jpg",
    images: ["Twin Cities Cover.jpg"],
    category: "CITY",
  },
  {
    id: "city_washington-dc",
    displayName: "Washington DC",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Washington DC Cover.jpg",
    images: [
      "Washington DC Attractions.jpg",
      "Washington DC Cover.jpg",
      "Washington DC Map.jpg",
      "Washington DC Radio etc.jpg",
    ],
    category: "CITY",
  },
];
