import React from 'react';
import Layout from '../../components/Layout';

const DayByDay = () => {

    return (
        <Layout title="Day by Day Datebooks" description="Day by Day (Christian) Datebooks is the same size and format as the City Datebook but includes sixteen pages of biblical references instead of city information. List of books of the Bible, Weight/Measurement conversions, List of Parables, List of Ten Commandments, List of Miracles, List of Letters of Paul and Glossary of Theological Terms." >
            <div className="text-center mt-5">
                <h1>Day by Day Datebooks</h1>
                <p>Day by Day (Christian) Datebooks is the same size and format as the City Datebook but includes sixteen pages of biblical references instead of city information. List of books of the Bible, Weight/Measurement conversions, List of Parables, List of Ten Commandments, List of Miracles, List of Letters of Paul and Glossary of Theological Terms.</p>
            </div>
        </Layout>
    );
}

export default DayByDay;