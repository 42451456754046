import React from 'react';
import Layout from '../components/Layout';

const LayoutAndDesign = () => {

    return (
        <Layout title="Layout and Design" description="Datebook Publishing, Inc. has been providing personalized datebooks for cities, businesses, and organizations since 1986. We are based in Dallas, Texas, but have a customer base that stretches from coast to coast." >
            <div id="layout-and-design" className="text-center" >
                <h1 className="text-center my-5">Datebook Layout and Design</h1>
                <img
                    src="/img/datebook_layout.jpg"
                    alt=""
                    style={{ width: "100%" }}
                />
            </div>            
        </Layout>
    );
}

export default LayoutAndDesign;