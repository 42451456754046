import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Store from '../pages/store';
import About from '../pages/About';
import LayoutAndDesign from '../pages/LayoutAndDesign'

import NotFound from '../pages/NotFound';
import Cart from "../pages/cart";
import DayByDay from '../pages/datebooks/DayByDay'
import BusinessAndOrganization from '../pages/datebooks/BusinessAndOrganization'
import City from "../pages/datebooks/City";
import Photo from "../pages/datebooks/Photo";
import ProductPDP from "../components/ProductPDP";
import ScrollToTop from "./ScrollToTop";

const Routes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/product/:productId" component={ProductPDP} />

          <Route path="/day-by-day" component={DayByDay} />
          <Route path="/business-and-organization" component={BusinessAndOrganization} />
          <Route path="/city" component={City} />
          <Route path="/photo" component={Photo} />

          <Route path="/layout-and-design" component={LayoutAndDesign}/> 
          <Route path="/about" component={About} />
          <Route exact path="/" component={Store} />
          <Route path="/cart" component={Cart} />
          <Route path="*" component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default Routes;