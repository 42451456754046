import React from 'react';
import Layout from '../../components/Layout';

import Image from 'react-bootstrap/Image'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const BusinessAndOrganization = () => {

    return (
        <Layout title="Business & Organization Datebooks" description="Datebook Publishing, Inc. has been providing personalized datebooks for cities, businesses, and organizations since 1986. We are based in Dallas, Texas, but have a customer base that stretches from coast to coast." >
            <div className="text-md-left text-sm-center mt-5">
                <h1>Business & Organization Datebooks</h1>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <p>We know that every business is different, so our Business and Organization Datebooks are specifically designed and customized for your company. The cover will feature a foil stamp of your company name and logo, as well as 16 pages that can contain any information of your choosing. For example, past orders have included company policies, important telephone numbers, photographs, and more. Whatever your needs, we can design and create a datebook that is perfect for you and your business. Business and Organization Datebooks are a great way to keep your organization’s name in front of your customers all year long!</p>
                        <p>In addition to the customizable pages, Business and Organization Datebooks include weekly and monthly calendar layouts for you to keep track of important dates, appointments, and notes. They will also include:</p>
                        <ul className="text-left">
                            <li>3 Year Reference Calendar.</li>
                            <li>List of important Dates and Holidays.</li>
                            <li>Pages for Birthdays and Anniversaries.</li>
                            <li>Restaurant & Entertainment and Book & Music & Movie Recommendations.</li>
                            <li>Expense Summary.</li>
                            <li>Goals and Resolutions.</li>
                        </ul>
                        <p>The smooth black leatherette covered book will fit easily into your purse or briefcase, with dimensions of 5 ½ x 7 ¾. Physical features include:</p>
                        <ul className="text-left">
                            <li>Black leatherette cover with foil embossed logo.</li>
                            <li>Wire-O binding to allow for full opening.</li>
                            <li>East tear off tabs to keep your place each week.</li>
                            <li>Pockets for loose papers such as receipts, event tickets, coupons, etc.</li>
                        </ul>
                        <p><a href="mailto:kurt@datebook.com">Email us</a> if we can personalize a Datebook for you! (250 quantity minimum)</p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="text-center">Sample Business Datebook</div>
                        <Carousel dynamicHeight={true} swipeable={true}>
                            {/* TODO: add max height to carousel */}
                            <div key={0}>
                                <img
                                    src={`/img/Business And Organization Datebooks/cover.jpg`}
                                    alt=""
                                />
                            </div>
                            {
                                [2, 4, 6, 8, 10].map(index => {
                                    return (
                                        <div key={index + 1}>
                                            <img
                                                src={`/img/Business And Organization Datebooks/page${index}-${index + 1}.jpg`}
                                                alt=""
                                            />
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="text-left mt-4">
                            <h2>How it works</h2>
                            <ol>
                                <li>Choose your personal style.</li>
                                <ol type="a">
                                    <li>Your full custom datebook will include your company name and logo on the cover and 16 full pages of personalized information.</li>
                                </ol>
                                <li><a href="https://www.datebook.com/po.pdf" rel="noopener noreferrer" target="_blank">Download a Purchase Order here</a></li>
                                <li>Send any artwork, images, and text with your design to <a href="mailto:kurt@datebook.com">kurt@datebook.com</a> and we will put it all together.</li>
                                <li>You will receive an email with a proof within 2-5 business days.</li>
                                <li>Add finishing touches and return the proof to us with your signature and date.</li>
                                <li>Production begins! Please allow for 2 weeks from the final approval for production. All orders are shipped via UPS Ground.</li>
                            </ol>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 d-flex align-items-center">
                        <Image src="/img/Business And Organization Datebooks/business_datebooks.jpg" className="mx-auto d-block" />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default BusinessAndOrganization;