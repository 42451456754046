import React from "react";
import Layout from "../../components/Layout";

import { Card, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import SignUp from "./signUp";

const Store = () => {
  // TODO: try implementing scrollTo functionality
  // const layoutAndDesignRef = useRef(null)

  // const executeScrollTo = (ref) => {
  //     if (ref) {
  //         ref.current.scrollIntoView({ behavior: 'smooth' })
  //     }
  // }

  const topRowData = [
    {
      title: `City Datebooks`,
      content: `Not only a practical daily planner, but are also a valuable city resource guide!`,
      imgSrc: `/img/City Datebooks/Dallas Cover.jpg`,
      link: "city",
    },
    {
      title: `Photo Datebooks`,
      content: `Beautiful and Practical, it includes scenic color photos as well as valuable state resources!`,
      imgSrc: `/img/Photo Datebooks/Photo Home.jpg`,
      link: "photo",
    },
  ];

  const bottomRowData = [
    {
      title: `Day By Day Datebooks`,
      content: `A Day By Day Datebook is an inspirational datebook PLUS sixteen pages of biblical references.`,
      imgSrc: `/img/Day By Day Datebooks/Day By Day Cover.jpg`,
      link: "product/daybyday",
    },
    {
      title: `Business & Organization Datebooks`,
      content: `Business and Organization Datebooks specifically designed and customized for your company.`,
      imgSrc: `/img/Business And Organization Datebooks/business_datebooks.jpg`,
      link: "business-and-organization",
    },
  ];

  const renderRow = (rowData) => {
    return (
      <Row>
        {rowData.map(({ title, content, imgSrc, link }, index) => {
          return (
            <div className="col-md-6 my-3">
              <Card>
                <Card.Img
                  variant="top"
                  src={imgSrc}
                  style={{
                    alignSelf: "center",
                    objectFit: "contain",
                    width: 230,
                    height: 230,
                    marginTop: 10,
                  }}
                />
                <Card.Body style={{ textAlign: "center" }}>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{content}</Card.Text>
                  <Link
                    className="btn btn-danger"
                    to={link}
                    role="button"
                    style={{ width: "100%" }}
                  >
                    Shop for {title}
                  </Link>
                </Card.Body>
              </Card>
            </div>
          );
        })}
      </Row>
    );
  };

  return (
    <Layout title="Home" description="This is the Home page">
      <div>
        <div className="text-center" style={{ marginBottom: 100 }}>
          <h1 className="my-5">What is a Datebook?</h1>
          <p style={{ fontSize: "1.25em" }}>
            <b>
              Datebook Publishing, Inc. has been providing personalized
              datebooks for cities, businesses, and organizations since 1986.{" "}
            </b>
          </p>
          <div className="my-4">
            <img src="/img/datebook-banner-black.jpg" alt="" />
          </div>
          <a
            className="btn btn-primary"
            // onClick={() => { executeScrollTo(layoutAndDesignRef) }}
            href="/layout-and-design"
          >
            Check out our latest Datebook layout and design
          </a>
        </div>

        <div id="shop" className="text-center">
          <h1 className="my-5">Shop Datebooks</h1>
          {renderRow(topRowData)}
          {renderRow(bottomRowData)}
        </div>

        <SignUp />

        {/* TODO: (11/13) This is kinda a hacky way but I didn't want to have to implement an offset of the navbar height for scrollTo */}
        {/* <div style={{ height: 90 }} ref={layoutAndDesignRef} />
                <div id="layout-and-design" className="text-center" >
                    <h1 className="text-center my-5">Datebook Layout and Design</h1>
                    <img
                        src="/img/datebook_layout.jpg"
                        alt=""
                        style={{ width: "100%" }}
                    />
                </div> */}
        {/* TODO: form to sign up for future discounts here */}
      </div>
    </Layout>
  );
};

export default Store;
