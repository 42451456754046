import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { getDatebookImageFolder } from '../helpers/utils';

const ImageCarousel = ({ product }) => {

    return (
        <Carousel dynamicHeight={true} swipeable={true}>
            <div key={0}>
                <img
                    src={`/img/${getDatebookImageFolder(product.category)}/${product.thumbnailImage}`} 
                    alt=""
                />
            </div>
            {product.images
                .filter(imgName => imgName !== product.thumbnailImage)
                .map((imgName, index) =>
                    <div key={index + 1}>
                        <img
                            src={`/img/${getDatebookImageFolder(product.category)}/${imgName}`} 
                            alt=""
                        />
                    </div>)
            }
        </Carousel>
    );
}

export default ImageCarousel;