export const dayByDayDatebooks = [
  {
    id: "daybyday",
    displayName: "Day by Day",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Day By Day Cover.jpg",
    images: [
      "Letters of Paul.jpg",
      "Ten Commandments.jpg",
      "Weights and Measures.jpg",
    ],
    category: "DAY_BY_DAY",
  },
];
