import React from 'react';
import Layout from '../../components/Layout';

import ProductsGrid from '../store/ProductsGrid';

const Photo = () => {

    return (
        <Layout title="Photo Datebooks" description="Photo City Datebooks include color photos of the city throughout the Datebook as well as helpful city resources including sixteen pages of city maps, transportation information, sports information and schedules, city attractions, important city phone numbers and calendar of events." >
            <div className="text-center mt-5">
                <h1>Photo Datebooks</h1>
                <p>Photo Datebook Weekly Planners include scenic color photos on the cover and throughout the book. It is not only beautiful and practical - it includes valuable state & city resources as well! Use it to keep track of important dates with weekly and monthly calendar layouts that give you ample space to write out daily appointments and notes, or use it as a helpful guide. Additionally, each Photo Datebook includes:</p>
                <ul className="text-left">
                    <li>Helpful resource guide including maps, transportation information, interesting facts, sports information, attractions and more!</li>
                    <li>3 Year Reference Calendar.</li>
                    <li>List of important Dates and Holidays.</li>
                    <li>Pages for Birthdays and Anniversaries.</li>
                    <li>Restaurant & Entertainment and Book & Music & Movie Recommendations.</li>
                </ul>
                <p>The smooth black leatherette covered book will fit easily into your purse or briefcase, with dimensions of 5 ½ x 7 ¾. Physical features include:</p>
                <ul className="text-left">
                    <li>Black leatherette cover with foil embossed logo.</li>
                    <li>Wire-O binding to allow for full opening.</li>
                    <li>East tear off tabs to keep your place each week.</li>
                    <li>Pockets for loose papers such as receipts, event tickets, coupons, etc.</li>
                </ul>
            </div>
            <ProductsGrid datebookType={'PHOTO'} />
        </Layout>
    );
}

export default Photo;