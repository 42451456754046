import React from 'react';
import styles from './footer.module.scss';

// TODO: add stuff here
const Footer = () => {
    return (
        <footer className={`${styles.footer}  mt-5 p-3`}>
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <h5>Contact Us</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md mb-3">
                            <b style={{ fontWeight: 'bold' }}>Datebook Publishing, Inc.</b><br />
                            9322 W. Lake Highlands Dr.<br />
                            Dallas, TX  75218<br />
                            (214) 321-5224<br />
                        </div>
                        <div className="col-md mb-3">
                            <b style={{ fontWeight: 'bold' }}>Order Line</b><br />
                            24-hour toll free<br />
                            (800) 909-6599 (orders only)<br />
                        </div>
                        <div className="col-md mb-3">
                            <b style={{ fontWeight: 'bold' }}>Customer Support</b><br />
                            Email: <a href="mailto:info@datebook.com">info@datebook.com</a>
                            <p><a href="mailto:kurt@datebook.com">Email us</a> if we can personalize a Datebook for you! (250 quantity minimum)</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-center">
                2020-{(new Date()).getFullYear()} &copy; Datebook Publishing.
            </div>
            {/* Design by <a href="https://robertleeluo.com/" target="_blank" rel="noopener noreferrer"><b>Robert Luo</b></a>  */}
        </footer>
    );
}

export default Footer;