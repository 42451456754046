import React, { useCallback, useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";

import CartProducts from "./CartProducts";
import { CartContext } from "../../contexts/CartContext";
import {
  formatNumber,
  extractNumberFromPriceString,
} from "../../helpers/utils";
import { Link } from "react-router-dom";

import { Alert, Form } from "react-bootstrap";

const Cart = () => {
  const { total, cartItems, itemCount, clearCart, checkout } =
    useContext(CartContext);

  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [isPromoNotValid, setIsPromoNotValid] = useState(false);
  const [promoInput, setPromoInput] = useState("");
  const [promoMessage, setPromoMessage] = useState("");

  // CART STATUS FUNCTION
  const renderCartStatus = (itemCount, isPromoApplied, total) => {
    let message = null;

    if (itemCount === 1) {
      message = "Order one more datebook for free standard shipping!";
    } else if (itemCount === 2 && !isPromoApplied) {
      message = "You qualified for free standard shipping!";
    } else if (isPromoApplied) {
      message =
        "Order one more datebook for free standard shipping with your BOGO!";
    } else if (itemCount < 5) {
      message =
        "Order 5 or more datebooks to get your datebooks for $14.95 each";
    }

    return message != null ? (
      <Alert variant="info" className="text-center">
        {message}
      </Alert>
    ) : null;
  };

  const onPromoButtonClick = useCallback(() => {
    if (
      Number(total) > 19.95 &&
      (promoInput.toLowerCase() === "bogo2024" ||
        promoInput.toLowerCase() === "2024bogo")
    ) {
      setIsPromoApplied(true);
      setPromoMessage(""); // Reset promoMessage when the valid promo code is applied and the total is above $19.95.
    } else if (
      promoInput.toLowerCase() === "bogo2024" ||
      promoInput.toLowerCase() === "2024bogo"
    ) {
      setPromoMessage(
        "To be able to use your BOGO, the order must be over $19.95"
      );
    } else if (
      promoInput.toLowerCase() !== "bogo2024" ||
      promoInput.toLowerCase() !== "2024bogo"
    ) {
      setIsPromoApplied(false);
      setIsPromoNotValid(true);
      setPromoMessage("");
    } else {
      setPromoMessage(""); // Reset promoMessage if the total is above $19.95 and the promo code is not valid.
    }

    // setPromoInput("");
  }, [total, promoInput]);

  useEffect(() => {
    onPromoButtonClick();
  }, [onPromoButtonClick]);

  const renderPromoCodeAlert = (promoMessage) => {
    if (promoMessage !== "") {
      return (
        <Alert variant="info" className="text-center">
          {promoMessage}
        </Alert>
      );
    } else {
      return null;
    }
  };

  function _getPriceFromShippingString(shippingString) {
    if (!shippingString) return;
    return Number(shippingString.split("$")[1]);
  }

  function _getShippingDescription(shippingString) {
    if (!shippingString) return;
    return shippingString.split(" - $")[0];
  }

  // console.log('shipping', _getPriceFromShippingString(shippingOption), _getShippingDescription(shippingOption))

  function _getOvernightShippingPrice(_itemCount) {
    if (_itemCount <= 2) {
      return 21.0;
    } else if (_itemCount <= 4) {
      return 42.0;
    } else {
      return _itemCount * 10.5;
    }
  }

  // TODO: there's a bug where the shipping option is stale if the user increments or decrements a cart item.
  const _getShippingOptions = (itemCount, isPromoApplied) => {
    // returns the Array<string> of shipping options
    let options = []; // initial option is null

    if (isPromoApplied === true && itemCount <= 2) {
      // push standard shipping
      options.push({
        option: `Standard Shipping (5 - 7 Business Days) - $${(3.2).toFixed(
          2
        )}`,
        value: 3.2,
      });
    } else if (itemCount < 2) {
      // push standard shipping
      options.push({
        option: `Standard Shipping (5 - 7 Business Days) - $${(3.2).toFixed(
          2
        )}`,
        value: 3.2,
      });
    } else {
      // push free shipping
      options.push({
        option: `Free Standard Shipping (5 - 7 Business Days) - $${(0.0).toFixed(
          2
        )}`,
        value: 0,
      });
    }

    // always have overnight shipping as an option
    options.push({
      option: `Overnight Shipping - $${_getOvernightShippingPrice(
        itemCount
      ).toFixed(2)}`,
      value: itemCount,
    });
    return options;
  };
  // console.log(_getShippingOptions(itemCount, isPromoApplied));

  const [shippingOption, setShippingOption] = useState(
    _getShippingOptions(itemCount, isPromoApplied)[0].option
  );

  const [shippingAmount, setShippingAmount] = useState(
    _getShippingOptions(itemCount, isPromoApplied)[0].value
  );

  // useEffect hook to update shipping amount when itemCount changes
  useEffect(() => {
    const updatedShippingAmount = _getShippingOptions(
      itemCount,
      isPromoApplied
    )[0].value;
    setShippingAmount(updatedShippingAmount);
  }, [itemCount, isPromoApplied]);

  const _handleShipping = (event) => {
    setShippingAmount(extractNumberFromPriceString(event.target.value));
    setShippingOption(event.target.value);
  };
  const getSavings = (_itemCount, _isPromoApplied) => {
    let savings = 0;

    if (_itemCount >= 5) {
      savings += 2.04 * _itemCount;
    }

    if (_isPromoApplied) {
      savings += _itemCount >= 5 ? 14.95 : 19.95;
    }
    return savings;
  };

  const getGrandTotal = (
    subtotal,
    _itemCount,
    _isPromoApplied,
    shippingAmount
  ) => {
    let grandTotal = Number(subtotal);

    if (_isPromoApplied) {
      grandTotal -= _itemCount >= 5 ? 14.95 : 19.95;
    }

    if (_itemCount >= 5 || _isPromoApplied) {
      grandTotal -= _itemCount * 2.04;
    }

    grandTotal += shippingAmount;

    return grandTotal;
  };

  return (
    <Layout title="Cart" description="This is the Cart page">
      <div>
        <div className="text-center mt-5">
          <h1 className="mb-3 text-danger">Cart</h1>
        </div>

        {renderCartStatus(itemCount, isPromoApplied, total)}
        {renderPromoCodeAlert(promoMessage)}
        <div className="row no-gutters justify-content-center">
          <div className="col-md-8 p-3">
            {cartItems.length > 0 ? (
              <>
                <CartProducts />

                {
                  // 5 ITEMS PROMO
                  itemCount >= 5 ? (
                    <div id="promo-5-items" className="row no-gutters py-2">
                      <div className="col-sm-2 p-2 d-none d-sm-block"></div>
                      <div className="col-6 col-sm-4 p-2">
                        <h5 className="mb-1">
                          {"PROMO: $14.95 each with 5 or more"}
                        </h5>
                        <p className="mb-1">Price: {formatNumber(-2.04)} </p>
                      </div>
                      <div className="col-4 col-sm-2 p-2 text-center ">
                        <p className="mb-0">Qty: {itemCount}</p>
                      </div>
                      <div className="col-2 col-sm-4 p-2 text-right"></div>
                    </div>
                  ) : null
                }

                {/* TODO: Adjustments - promo and shipping */}
                <span
                  className="lineitem"
                  style={{ display: "none" }}
                  data-itemid="shipping"
                  data-quantity={1}
                  data-price={_getPriceFromShippingString(shippingOption)}
                  data-name={_getShippingDescription(shippingOption)}
                ></span>

                {isPromoApplied ? (
                  <>
                    <div id="promo-lineitem" className="row no-gutters py-2">
                      <div className="col-sm-2 p-2 d-none d-sm-block"></div>
                      <div className="col-6 col-sm-4 p-2">
                        <h5 className="mb-1">{"BOGO COUPON"}</h5>
                        <p className="mb-1">
                          Price:{" "}
                          {formatNumber(itemCount >= 5 ? -14.95 : -19.95)}{" "}
                        </p>
                      </div>
                      <div className="col-4 col-sm-2 p-2 text-center ">
                        <p className="mb-0">Qty: {1}</p>
                      </div>
                      <div className="col-2 col-sm-4 p-2 text-right"></div>
                    </div>

                    <span
                      className="lineitem"
                      style={{ display: "none" }}
                      data-itemid="coupon"
                      data-quantity={1}
                      data-price={itemCount >= 5 ? -14.95 : -19.95}
                      data-name={"BOGO COUPON"}
                    ></span>
                  </>
                ) : null}
              </>
            ) : (
              <div className="p-3 text-center text-muted">
                Your cart is empty
              </div>
            )}

            {checkout && (
              <div className="p-3 text-center text-success">
                <p>Checkout successfull</p>
                <Link to="/" className="btn btn-outline-success btn-sm">
                  BUY MORE
                </Link>
              </div>
            )}
          </div>
          {cartItems.length > 0 && (
            <div className="col-md-4 p-3">
              <div className="card card-body">
                <p className="mb-1">Total Items</p>
                <h4 className=" mb-3 txt-right">{itemCount}</h4>
                <div id="shipping-options">
                  <Form>
                    <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Label>Shipping Options</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        onChange={_handleShipping}
                        value={
                          shippingOption ||
                          _getShippingOptions(itemCount, isPromoApplied)[0]
                            .option
                        }
                      >
                        {_getShippingOptions(itemCount, isPromoApplied).map(
                          (opt, index) => (
                            <option key={index}>{opt.option}</option>
                          )
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </div>

                <div id="promo-group">
                  <label>Promo Code</label>
                  <div id="promo-input" className="input-group mb-3">
                    <input
                      // disabled={Number(total)}
                      onChange={(evt) => {
                        setPromoInput(evt.target.value);
                      }}
                      // value={promoInput}
                      type="text"
                      className="form-control"
                      placeholder="Promo code"
                      aria-label="Promo code"
                      aria-describedby="basic-addon2"
                    />
                    <button
                      onClick={onPromoButtonClick}
                      className="btn btn-sm btn-danger input-group-append"
                      style={{ alignItems: "center" }}
                    >
                      Apply Promo
                    </button>
                  </div>
                  <div
                    id="promo-message"
                    style={{ fontSize: "0.5em", color: "red" }}
                  >
                    {isPromoApplied
                      ? "promo code applied!"
                      : isPromoNotValid
                      ? "promo code not valid!"
                      : null}
                  </div>
                </div>

                <div id="subtotal">
                  <p className="mb-1">Subtotal</p>
                  <h3 className="m-0 txt-right">{formatNumber(total)}</h3>
                </div>

                {shippingAmount > 0 ? (
                  <div id="savings">
                    <p className="mb-1">Shipping</p>
                    <h3 className="m-0 txt-right">
                      {formatNumber(shippingAmount)}
                    </h3>
                  </div>
                ) : null}

                {itemCount >= 5 || isPromoApplied ? (
                  <div id="savings">
                    <p className="mb-1">Savings</p>
                    <h3 className="m-0 txt-right">
                      {formatNumber(getSavings(itemCount, isPromoApplied))}
                    </h3>
                  </div>
                ) : null}

                <div id="grand-total">
                  <p className="mb-1">Grand Total</p>
                  <h3 className="m-0 txt-right">
                    {formatNumber(
                      getGrandTotal(
                        total,
                        itemCount,
                        isPromoApplied,
                        shippingAmount
                      )
                    )}
                  </h3>
                </div>

                <hr className="my-4" />
                <div className="text-center">
                  <button
                    id="btn_checkout"
                    disabled={shippingOption == null}
                    type="button"
                    className="btn btn-primary mb-2"
                    onClick={() => {
                      console.log("clicked checkout");
                    }}
                  >
                    CHECKOUT
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary mb-2"
                    onClick={clearCart}
                  >
                    CLEAR
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <span id="debugger"></span>
      </div>
    </Layout>
  );
};

export default Cart;
