import React from 'react';
import Layout from '../components/Layout';

const About = () => {

    return (
        <Layout title="About" description="Datebook Publishing, Inc. has been providing personalized datebooks for cities, businesses, and organizations since 1986. We are based in Dallas, Texas, but have a customer base that stretches from coast to coast." >
            <div className="text-center my-5">
                <h1 className="mb-4">About Us</h1>
                {/* <img className="my-3" src="/img/Business And Organization Datebooks/business_datebooks.jpg" alt="about Datebooks" style={{ height: 200 }} /> */}
                <p>Datebook Publishing, Inc. has been providing personalized datebooks for cities, businesses, and organizations since 1986. We are based in Dallas, Texas, but have a customer base that stretches from coast to coast.</p>
                <p>The idea for personalized daily planners was born in a college classroom at Southern Methodist University. While the concept of datebooks is nothing new, our concept is unique because we combine a useful daily planner with resource guides that gives important and valuable city information.</p>
                <p>In addition to selling datebooks personalized for cities, we also create personalized planners for businesses and organizations. We offer up to sixteen pages that can be personalized with information about the organization, product descriptions, letters to customers or employees, important phone numbers and much more. The organization also gets their name and logo foil-stamped and embossed on the cover. Those datebooks provide excellent promotional ideas for organizations to give to employees, customers and prospects. At around $5 per book (with a minimum order of 250), our datebooks give 365 days of exposure for less than the price of lunch at a fast food place.</p>
                <p>We would like for you to learn more about our products so please take a few minutes to examine our WebPage. In addition, we would love to hear from you with questions or comments. Please feel free to email us at <a href="mailto:info@datebook.com">info@datebook.com</a></p>
            </div>

            <div className="row text-center">
                <div className="border border-secondary col-md mb-3 mx-2 p-3">
                    <h3 style={{ color: "#d9534f", textAlign: 'center' }}>Warranty information</h3>
                    <p>If for some reason you are not satisfied with your purchase, simply send it back to us within three weeks of your purchase and we will refund the purchase price once we receive the returned product. Shipping costs will not be refunded. Please allow up to two weeks for processing.</p>
                </div>
                <div className="border border-secondary col-md mb-3 mx-2 p-3">
                    <h3 style={{ color: "#390", textAlign: 'center' }}>Green</h3>
                    <img src="/img/green.gif" alt="green" />
                    <p>SFI Product (Sustainable Forestry Initiative) - A comprehensive system of principles, objectives and performance measures that combines the perpetual growing and harvesting of trees with the protection of wildlife, plants, soil and water quality. Independent certification documents the chain of custody through all stages of production back to the forest of origin.</p>
                </div>
                <div className="border border-secondary col-md mb-3 mx-2 p-3">
                    <h3 style={{ color: "#d9534f", textAlign: 'center' }}>Made in the USA</h3>
                    <p>Our accessories are proudly made in the USA.</p>
                </div>
            </div>
        </Layout>
    );
}

export default About;