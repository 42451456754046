export const photoDatebooks = [
  {
    id: "photo_arizona",
    displayName: "Arizona",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Arizona Cover.jpg",
    images: ["Arizona Cover.jpg", "Arizona Parks.jpg"],
    category: "PHOTO",
  },
  {
    id: "photo_california",
    displayName: "California",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "California Cover.jpg",
    images: ["California Cover.jpg", "California National Parks.jpg"],
    category: "PHOTO",
  },
  {
    id: "photo_colorado",
    displayName: "Colorado",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Colorado Cover.jpg",
    images: ["Colorado Cover.jpg", "Colorado Ski Info.jpg"],
    category: "PHOTO",
  },
  {
    id: "photo_dallas",
    displayName: "Dallas",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Dallas Cover.jpg",
    images: ["Dallas Cover.jpg"],
    category: "PHOTO",
  },
  {
    id: "photo_florida",
    displayName: "Florida",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Florida Cover.jpg",
    images: ["Florida Cover.jpg"],
    category: "PHOTO",
  },
  {
    id: "photo_michigan",
    displayName: "Michigan",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Michigan Cover.jpg",
    images: ["Michigan Cover.jpg"],
    category: "PHOTO",
  },
  {
    id: "photo_new-york",
    displayName: "New York",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "New York Cover.jpg",
    images: ["New York Cover.jpg"],
    category: "PHOTO",
  },
  {
    id: "photo_oregon",
    displayName: "Oregon",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Oregon Cover.jpg",
    images: ["Oregon Cover.jpg", "Oregon State Facts.jpg"],
    category: "PHOTO",
  },
  {
    id: "photo_san-francisco",
    displayName: "San Francisco",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "San Francisco Cover.jpg",
    images: ["San Francisco Cover.jpg"],
    category: "PHOTO",
  },
  {
    id: "photo_texas",
    displayName: "Texas",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Texas Cover.jpg",
    images: ["Texas Cover.jpg", "Texas Sports.jpg"],
    category: "PHOTO",
  },
  {
    id: "photo_usa",
    displayName: "USA",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "USA Cover.jpg?y=2023",
    images: ["USA Cover.jpg?y=2023"],
    category: "PHOTO",
  },
  {
    id: "photo_utah",
    displayName: "Utah",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Utah Cover.jpg",
    images: ["Utah Cover.jpg"],
    category: "PHOTO",
  },
  {
    id: "photo_washington",
    displayName: "Washington",
    description: "Lorem Ipsum",
    price: 19.95,
    thumbnailImage: "Washington Cover.jpg",
    images: ["Washington Cover.jpg", "Washington Map.jpg"],
    category: "PHOTO",
  },
];
