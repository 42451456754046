import React from 'react';
import Layout from '../../components/Layout';

import ProductsGrid from '../store/ProductsGrid';

const City = () => {

    return (
        <Layout title="City Datebooks" description="City Datebooks include helpful city resources including sixteen pages of city maps, transportation information, sports information and schedules, city attractions, important city phone numbers and calendar of events." >
            <div className="text-center mt-5">
                <h1>City Datebooks</h1>
                <p>City Datebook Weekly Planners are not only a practical daily planner, but are also a valuable city resource guide! It will help you keep track of all important dates in your life and give you insight into the beautiful city you are in. Use it to keep track of important dates with weekly and monthly calendar layouts for you to write in your daily appointments and notes. Additionally, each City Datebook includes:</p>
                <ul className="text-left">
                    <li>Helpful city resources such as city maps, transportation information, sports information and schedules, local attractions, a calendar of city events, and more!</li>
                    <li>3 Year Reference Calendar.</li>
                    <li>List of important Dates and Holidays.</li>
                    <li>Pages for Birthdays and Anniversaries.</li>
                    <li>Restaurant & Entertainment and Book & Music & Movie Recommendations.</li>
                </ul>
                <p>The black leatherette covered book will fit easily into your purse or briefcase, with dimensions of 5 ½ x 7 ¾. Physical features include:</p>
                <ul className="text-left">
                    <li>Black leatherette cover with foil embossed logo.</li>
                    <li>Wire-O binding to allow for full opening.</li>
                    <li>East tear off tabs to keep your place each week.</li>
                    <li>Pockets for loose papers such as receipts, event tickets, coupons, etc.</li>
                </ul>
            </div>
            <ProductsGrid datebookType={'CITY'}/>
        </Layout>
    );
}

export default City;