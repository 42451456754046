import React, { createContext, useState } from 'react';
import { cityDatebooks } from '../services/cityDatebookData'
import { photoDatebooks } from '../services/photoDatebookData'
import { dayByDayDatebooks } from '../services/dayByDayDatebookData'

export const ProductsContext = createContext()

const ProductsContextProvider = ({children}) => {

    const [products] = useState([...cityDatebooks, ...photoDatebooks, ...dayByDayDatebooks]);


    return ( 
        <ProductsContext.Provider value={{products}} >
            { children }
        </ProductsContext.Provider>
     );
}
 
export default ProductsContextProvider;